.custom-table .ant-table-thead > tr > th,
.custom-table .ant-table-tbody > tr > td {
  border-right: 2px solid #f4f4f4
}

#root > div.ant-layout.ant-layout-has-sider.css-dev-only-do-not-override-1gwfwyx > div > main > div > div > div > div.ant-page-header-content > div > div > div > div > div > div > div > table > thead > tr > th{
  text-align: center;
}

#root > div.ant-layout.ant-layout-has-sider.css-dev-only-do-not-override-1gwfwyx > div > main > div > div > div > div.ant-page-header-content > div > div > div > div > div > div > div > table > tbody > tr > td > div > div > div > div > div > div > table > thead > tr > th{
  text-align: center;
}

#root .ant-layout .ant-page-header-content table tbody tr td:nth-child(2) {
    padding-inline: 0px !important;
}

#root .ant-layout .ant-page-header-content table tbody tr td:nth-child(2) div {
    margin-inline: 0px !important;
}
